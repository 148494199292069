<template>
  <div class="app-container">
    <eHeader ref="header" :query="query" @toQuery="toQuery" @clear="toClearInit" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" border style="width: 100%;">
      <el-table-column prop="order" label="订单ID" width="130">
        <template slot-scope="scope">
          <div>{{scope.row.copyOrder}} (跟)</div>
          <div>{{scope.row.signalOrder}} (信)</div>
        </template>
      </el-table-column>
      <el-table-column prop="login" label="LOGIN" width="130">
        <template slot-scope="scope">
          <div>{{scope.row.copyLogin	}} (跟)</div>
          <div>{{scope.row.signalLogin}} (信)</div>
        </template>
      </el-table-column>
      <el-table-column prop="cmd" label="交易类型">
        <template slot-scope="scope">
          <div>{{scope.row.signalCmd == 0 ? 'BUY' : 'SELL'}} </div>
        </template>
      </el-table-column>
      <!--      ，0-买 1-卖-->
      <el-table-column prop="signalSymbol" label="交易品种"/>
      <el-table-column prop="volume" label="交易量 (*0.01手)" width="130">
        <template slot-scope="scope">
          <div>{{scope.row.copyVolume}} (跟)</div>
          <div>{{scope.row.signalVolume}} (信)</div>
        </template>
      </el-table-column>
      <el-table-column prop="tradeCmd" label="交易操作">
        <!--      1-开仓 2-平仓 3-自主平仓 4-解除绑定-->
        <template slot-scope="scope">
          <span>{{getTradeType(scope.row.tradeCmd)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="跟单状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">成功</span>
          <span v-else>失败</span>
        </template>
      </el-table-column>
      <el-table-column  prop="reason" label="失败原因">
        <template slot-scope="scope">
          <span v-if="scope.row.status!=0" >{{ scope.row.reason || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="detailHandle(scope.row.id)">查看详情</el-button>
          <el-button
            style="margin-top:3px;"
            v-if="scope.row.tradeCmd == 2&&scope.row.status!=0&&checkPermission(['ADMIN','COPY_RELATION_ALL','COPY_RELATION_EDIT'])"
            size="mini"
            type="danger"
            @click="closeOrder(scope.row.id)"
          >平仓</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <dialog-box
      :data-table="dataTable"
      :dialog-visible="dialogVisible"
      @close-dialog="dialogVisible = false"></dialog-box>
  </div>
</template>

<script>

import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del, getDetail,closeOrder } from '@/api/copy/tradeCopyRelation'
import { getSitesData } from '@/api/cms/srSite'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/copy/suborder/header'
import dialogBox from '@/components/copy/suborder/dialogBox'
import edit from '@/components/copy/suborder/edit'
export default {
  name:'suborder',
  components: { eHeader, edit, dialogBox },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      dataTable:[],
      dialogVisible: false,
    }
  },
  mounted() {
    if(this.$route.query&&this.$route.query.login) {
      this.query ={
        login : this.$route.query.login,
        status:1,
        startTime:this.$route.query.startTime,
        endTime:this.$route.query.endTime,
      }
    }
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/crm/queryCopyRelationList';
      this.params = {
        page: this.page,
        size: this.size,
      };
      const query = this.query;
      const copyOrder	 = query.copyOrder;
      const copyLogin = query.copyLogin;
      const signalLogin = query.signalLogin;
      const status = query.status;
      const tradeCmd = query.tradeCmd;
      const startTime = query.startTime;
      const endTime = query.endTime;
      if (copyOrder !== "" && copyOrder !== null) {
        this.params["copyOrder"] = copyOrder	;
      }
      if (copyLogin !== "" && copyLogin !== null) {
        this.params["copyLogin"] = copyLogin;
      }
      if (signalLogin !== "" && signalLogin !== null) {
        this.params["signalLogin"] = signalLogin;
      }
      if (status !== "" && status !== null) {
        this.params["status"] = status;
      }
      if (tradeCmd !== "" && tradeCmd !== null) {
        this.params["tradeCmd"] = tradeCmd;
      }
      if (startTime !== undefined && startTime !== "" && startTime !== null) {
        this.params["startTime"] = parseTime(startTime);
      }
      if (endTime !== undefined && endTime !== "" && endTime !== null){
        this.params["endTime"] = parseTime(endTime);
      }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    getSites() {
      const params = {page:0,size:200}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    detailHandle(id){
      getDetail({relationId:id}).then((res) => {
        this.dataTable = res.content || [];
        this.dialogVisible = true;
      })
    },
    closeOrder(id){
      closeOrder(id).then((res) => {
         this.init()
         this.$notify({
          title: '手动平仓提交成功，系统处理中！',
          type: 'success',
          duration: 2500
        })
      })
    },
    getTradeType(type){
      switch (type) {
        case 1:
          return '开仓';
        case 2:
          return '平仓';
        case 3:
          return '自主平仓';
        case 4:
          return '解除绑定';
        default:
          return '开仓';
      }
    },
    getStatus(status){
      let text  = '';
      switch (status) {
        /*<!--          Success("成功", 0),-->
        <!--          Fail_Risk("风控策略限制", 1),-->
        <!--          Fail_Risk_Stop("跟单功能已关闭", 101),-->
        <!--          Fail_Risk_MaxLots("交易量超限", 102),-->
        <!--          Fail_Risk_MaxNum("交易笔数超限", 103),-->
        <!--          Fail_Risk_Login_Stop("账户跟单功能已关闭", 104),-->
        <!--          Fail_Risk_Login_MaxLots("账户交易量超限", 105),-->
        <!--          Fail_Mt4("账户及风控设置", 2),-->
        <!--          Fail_TimeOut("网络超时", 3),-->
        <!--          Open_Waiting("待开仓", 4),-->
        <!--          Close_Waiting("待平仓", 5),-->
        <!--          Fail_Symbols("指数产品不能跟单", 6),-->
        <!--          Fail_User_Delete("用户已失效", 7),-->
        <!--          Fail_Other("其它原因", 99);-->*/
        case 0:
          text = '成功';
          break;
        case 1:
          text = '失败(风控策略)';
          break;
        case 2:
          text = '失败(账户及风控设置)';
          break;
        case 3:
          text = '失败(网路超时)';
          break;
        case 4:
          text = '失败(待开仓)';
          break;
        case 5:
          text = '失败(待平仓)';
          break;
        case 6:
          text = '失败(指数产品不能跟单)';
          break;
        case 7:
          text = '失败(用户已失效)';
          break;
        case 99:
          text = '失败（其它原因）';
          break;
        case 102:
          text = '失败（交易量超限）';
          break;
        case 103:
          text = '失败（交易笔数超限）';
          break;
        case 104:
          text = '失败（账户跟单功能已关闭）';
          break;
        case 105:
          text = '失败（账户交易量超限）';
          break;
      }
      return  text;
    },
    getOrder(row){
      if(row.status !== 0){
        return '-';
      }else {
        return row.order;
      }
    },
    toClearInit() {
      this.query = {}
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
