<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            class="align-top filter-item"
            clearable
            v-model="query.status"
            filterable placeholder="跟单状态"
            style="width: 150px;"
            @change="toQuery"
    >
      <el-option
              v-for="item in statusOptoins"
              :key="item.keyValue"
              :value="item.keyValue"
              :label="item.key"
      >
      </el-option>
    </el-select>
    <el-select
            class="align-top filter-item"
            clearable
            v-model="query.tradeCmd"
            filterable
            placeholder="交易操作"
            style="width: 130px;"
            @change="toQuery"
    >
      <el-option
              v-for="item in operOptoins"
              :key="item.keyValue"
              :value="item.keyValue"
              :label="item.key"
      >
      </el-option>
    </el-select>
    <el-input v-model="query.copyOrder" clearable placeholder="订单id" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.copyLogin" clearable placeholder="跟随者login" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.signalLogin" clearable placeholder="信号源login" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery"/>


    <el-date-picker
      class="align-top"
      style="width: 150px;"
      v-model="query.startTime"
      type="date"
      placeholder="开始日期">
    </el-date-picker>
    至
    <el-date-picker
      class="align-top filter-item"
      style="width: 150px;"
      v-model="query.endTime"
      type="date"
      placeholder="结束日期">
    </el-date-picker>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button
            type="primary"
            class="filter-item"
            size="mini"
            @click="toClearInit"
            icon="el-icon-close"
    >清空</el-button>
  </div>
</template>

<script>
import accountType from '@/components/copy/accountType'
export default {
  components: {
    accountType
  },
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      statusOptoins:[
        {key:'成功',keyValue:0},
        {key:'失败',keyValue:1}
      ],
      operOptoins:[
        {key:'开仓',keyValue:1},
        {key:'平仓',keyValue:2},
        {key:'自主平仓',keyValue:3},
        {key:'取消跟随',keyValue:4}
      ]
    }
  },
  methods: {
    toQuery() {
      this.$emit('toQuery');
    },
    toClearInit(){
      this.$emit('clear');
    },
  }
}
</script>
<style scoped lang="less">
  .align-top {
    vertical-align: top;
  }
</style>
