<template>
  <el-dialog
    title="跟单交易详情"
    :visible="dialogVisible"
    width="70%"
    :before-close="handleClose"
    append-to-body
  >
    <el-table :data="dataTable" stripe style="width: 100%;">
      <el-table-column prop="relationId" label="跟单ID" width='130'/>
      <el-table-column prop="tranCmd" label="交易类型">
        <template slot-scope="scope">
          <span>{{scope.row.tranCmd == 0 ? 'BUY' : 'SELL'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tranType" label="交易操作">
        <template slot-scope="scope">
          <span>{{scope.row.tranType == 75 ? '开仓' : '平仓'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tranOrder" label="订单ID" width='130'/>
      <el-table-column prop="tranOrderby" label="订单账号" width='130'/>
      <el-table-column prop="tranSymbol" label="交易产品" />
      <el-table-column prop="tranVolume" label="交易量" />
      <el-table-column prop="tranPrice" label="交易价格" />
      <el-table-column prop="retStatus" label="状态">
        <!-- 0 成功  1 失败-->
        <template slot-scope="scope">
          <span>{{scope.row.retStatus == '0' ? '成功' : '失败'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="retMessage" label="消息" />
      <el-table-column prop="tranComment" label="Comment"  width='130' :show-overflow-tooltip="true"/>
       <el-table-column label="创建时间" width='200'>
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  import { parseTime } from "@/utils/index";
  export default {
    name: 'dialogBox',
    props:{
      dialogVisible:{
        default: false
      },
      dataTable:{
        type: Array,
        required: true
      }
    },
    methods: {
      parseTime,
      handleClose() {
        this.$emit('close-dialog');
      },
    }
  }
</script>

<style scoped>

</style>
